
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'header-cart',
})
export default class extends Vue {
  @Prop() readonly label: string;
  @Prop() readonly quoteItemCount: number;

  private itemCount: number = 0;

  protected get totalsAvailable(): boolean {
    return this.$store.getters['CheckoutTotals/totalsAvailable'] || false;
  }

  private created(): void {
    this.itemCount = this.quoteItemCount;
    this.$store.dispatch('CheckoutTotals/fetchCartTotals');

    window.addEventListener('CheckoutAddedProductToCartEvent', () => {
      this.$store.dispatch('CheckoutTotals/fetchCartTotals');
    });

    window.addEventListener('CheckoutAddedProductToCartCount', () => {
      this.itemCount = this.$store.state['CheckoutTotals']['cartTotals'].items_qty;
    });
  }
}
