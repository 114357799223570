/**
 * Import all polyfills.
 */
import 'url-search-params-polyfill';
import 'custom-event-polyfill';

/**
 * Import all required libraries.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import ServiceContainer from '@flashpointbv/solar-service-container';
import Environment from '@flashpointbv/solar-ui-environment';
import VueI18n from 'vue-i18n';
import VueLazyload from 'vue-lazyload';
import 'lazysizes';

/*
 * Create store instance and register the store modules
 */
import {CatalogBundleProduct, CatalogCategory, CatalogGroupedProduct, CatalogProduct, CatalogConfigurableProduct} from '@flashpointbv/solar-ui-catalog/src';
import {CheckoutAddress, CheckoutCart, CheckoutGlobal, CheckoutPayment, CheckoutQuote, CheckoutShipping, CheckoutTotals} from '../js/stores/checkout';
import GlobalMessages from './stores/messages';
import QuotationPopup from './stores/quotation';
import ProductQuantity from './stores/quantity';
import ProductDataLayer from './stores/product/datalayer';
import ProductView from './stores/product';
import GlobalSettings from './stores/global';

/**
 * Import all required styles.
 */
import '../scss/app.scss';
import {UICoreBlazeSlider, UICoreOffCanvas, UICoreSticky} from '@flashpointbv/solar-ui-core';
import BlazeSlider from '@flashpointbv/blaze-slider/dist/blaze-slider.esm';

import {localize} from 'vee-validate';
import vee_nl_NL from 'vee-validate/dist/locale/nl.json';
import nl_NL from './../../../../lang/vue-translations/nl_NL';
import {SolarDataLayer} from "./shared/SolarDataLayer";

new UICoreSticky().sticky();
new UICoreOffCanvas().offCanvas(true);
new UICoreBlazeSlider();
window.BlazeSlider = BlazeSlider;

Vue.use(VueI18n);
Vue.use(VueLazyload);
Vue.use(Vuex);

SolarDataLayer.init({
    'item_id': 'item_id',
    'item_group_id': 'item_group_id',
    'item_variant_id': 'item_variant_id',
    'item_variant': 'item_variant',
});

/**
 * Set important environment variables
 */
Environment().set('locale', window.Locale);
Environment().set('locale_short', window.Locale.substring(0, 2).toLowerCase());
for (let key in window.Config) Environment().set(key, window.Config[key]);

const store = new Vuex.Store({
    strict: false,
});

store.registerModule('GlobalSettings', GlobalSettings)
store.registerModule('GlobalMessages', GlobalMessages);
store.registerModule('ProductView', ProductView);
store.registerModule('CatalogCategory', CatalogCategory);
store.registerModule('CatalogProduct', CatalogProduct);
store.registerModule('CatalogBundleProduct', CatalogBundleProduct);
store.registerModule('CatalogGroupedProduct', CatalogGroupedProduct);
store.registerModule('CatalogConfigurableProduct', CatalogConfigurableProduct);
store.registerModule('ProductDataLayer', ProductDataLayer);

/**
 * Require application scripts.
 */
require('./prototypes');
require('./core');
require('./off-canvas');

store.registerModule('CheckoutQuote', CheckoutQuote);
store.registerModule('CheckoutGlobal', CheckoutGlobal);
store.registerModule('CheckoutTotals', CheckoutTotals);
store.registerModule('CheckoutPayment', CheckoutPayment);
store.registerModule('CheckoutAddress', CheckoutAddress);
store.registerModule('CheckoutShipping', CheckoutShipping);
store.registerModule('CheckoutCart', CheckoutCart);
store.registerModule('QuotationPopup', QuotationPopup);
store.registerModule('ProductQuantity', ProductQuantity);

/**
 * Register Vue components.
 */
ServiceContainer().addComponents([
    {key: 'global-messages', concrete: () => import('./components/GlobalMessages.vue')},
    {key: 'global-modal', concrete: () => import('./components/GlobalModal.vue')},
    {key: 'price-toggle', concrete: () => import('./components/PriceToggle.vue')},
    {key: 'search-form', concrete: require('./components/search/SearchForm.vue').default},
    {key: 'header-cart', concrete: require('./components/HeaderCart').default},
    {key: 'newsletter-block', concrete: () => import('./components/Newsletterblock.vue')},
    {key: 'search-result-suggested-categories', concrete: () => import('./components/search/results/SearchResultSuggestedCategories.vue')},
    {key: 'search-result-suggested-filters', concrete: () => import('./components/search/results/SearchResultSuggestedFilters.vue')},
    {key: 'search-result-suggested-products', concrete: () => import('./components/search/results/SearchResultSuggestedProducts.vue')},
    {key: 'search-result-suggested-keywords', concrete: () => import('./components/search/results/SearchResultSuggestedKeywords.vue')},
    {key: 'tweakwise-recommendations', concrete: () => import('../../../../../vendor/happy-horizon/tweakwise-recommendations/resources/resources/assets/js/components/TweakwiseRecommendations.vue')},

    //Catalog
    {key: 'catalog-category-view', concrete: () => import('./views/catalog/CatalogCategoryView.vue')},
    {key: 'catalog-search-view', concrete: () => import('./views/catalog/CatalogSearchView.vue')},
    {key: 'catalog-product-list', concrete: () => import('./components/catalog/ProductList.vue')},
    {key: 'catalog-product-list-item', concrete: () => import('./components/catalog/ProductListItem.vue')},
    {key: 'catalog-product-list-toolbar', concrete: () => import('./components/catalog/toolbar/ProductListToolbar.vue')},
    {key: 'catalog-search', concrete: () => import('./components/catalog/CategorySearch.vue')},
    {key: 'catalog-product-list-toolbar-paginator', concrete: () => import('./components/catalog/toolbar/ProductListToolbarPaginator.vue')},
    {key: 'catalog-product-list-toolbar-sort-select', concrete: () => import('./components/catalog/toolbar/ProductListToolbarSortSelect.vue')},
    {key: 'catalog-product-list-toolbar-size-select', concrete: () => import('./components/catalog/toolbar/ProductListToolbarSizeSelect.vue')},
    {key: 'catalog-product-list-toolbar-active-filters', concrete: () => import('./components/catalog/toolbar/ProductListToolbarActiveFilters.vue')},
    {key: 'catalog-product-configuration', concrete: () => import('./components/catalog/product/ProductConfiguration.vue')},
    {key: 'catalog-product-family-products', concrete: () => import('./components/catalog/product/ProductFamilies.vue')},
    {key: 'catalog-product-simple', concrete: () => import('../../default/js/components/catalog/product/simple/ProductTypeSimple.vue')},
    {key: 'catalog-product-configurable', concrete: () => import('../../default/js/components/catalog/product/configurable/ProductTypeConfigurable.vue')},
    {key: 'catalog-product-configurable-select', concrete: () => import('../../default/js/components/catalog/product/configurable/type/OptionSelect.vue')},
    {key: 'catalog-product-configurable-swatch', concrete: () => import('../../default/js/components/catalog/product/configurable/type/OptionSwatch.vue')},
    {key: 'catalog-product-configurable-swatch-list', concrete: () => import('../../default/js/components/catalog/product/configurable/type/OptionSwatchList.vue')},
    {key: 'catalog-product-swatch-list-add-to-cart', concrete: () => import('../../default/js/components/catalog/product/SwatchListAddToCart.vue')},
    {key: 'catalog-product-price', concrete: () => import('./components/catalog/product/ProductPrice.vue')},
    {key: 'catalog-product-stock-delivery-indicator', concrete: () => import('./components/catalog/product/ProductStockDeliveryIndicator.vue')},
    {key: 'catalog-product-recent', concrete: () => import('./components/catalog/product/ProductRecentList.vue')},
    {key: 'catalog-product-tier-prices', concrete: () => import('./components/catalog/product/ProductTierPrices.vue')},

    //Catalog Filters
    {key: 'catalog-product-filter-list', concrete: () => import('./components/catalog/ProductFilterList.vue')},
    {key: 'catalog-product-list-attribute-filter', concrete: () => import('./components/catalog/filter/AttributeFilter.vue')},
    {key: 'catalog-product-list-swatch-filter', concrete: () => import('./components/catalog/filter/SwatchFilter.vue')},
    {key: 'catalog-product-list-price-filter', concrete: () => import('./components/catalog/filter/PriceFilter.vue')},
    {key: 'cart-datalayer', concrete: () => import('./components/checkout/cart/CartDatalayer.vue')},
    {key: 'catalog-product-add-to-cart', concrete: () => import('./components/catalog/product/ProductAddToCart.vue')},

    //Customer
    {key: 'customer-address-autocomplete', concrete: () => import('./components/customer/AddressAutocomplete.vue')},
    {key: 'quotation-popup', concrete: () => import('./components/quotation/popup.vue')},
    {key: 'customer-country-and-address', concrete: require('./components/customer/CountryAndAddress.vue').default},

    //Checkout
    {key: 'cart-item-price', concrete: () => import('./components/checkout/cart/CartItemPrice.vue')},
    {key: 'cart-item-row-price', concrete: () => import('./components/checkout/cart/CartItemRowPrice.vue')},
    {key: 'checkout-checkout', concrete: require('./components/checkout/Checkout.vue').default},
    {key: 'success-datalayer', concrete: require('./components/checkout/SuccessDatalayer.vue').default},
    {key: 'landing-page-form-block', concrete: () => import('./components/LandingPageFormBlock.vue')},

    //Lazy load components
    {key: 'lazy-load', concrete: () => import('./components/LazyLoad.vue')},
]);

localize({vee_nl_NL});
localize(`vee_${window.Locale}`);

const messages = {
    nl_NL,
};

const i18n = new VueI18n({
    locale: window.Locale,
    fallbackLocale: 'nl_NL',
    formatFallbackMessages: true,
    silentTranslationWarn: true,
    messages,
});

new Vue({
    store,
    i18n,
    components: ServiceContainer().getComponents(),
    data: {
        csrfToken: window.csrfToken,
    },
    async created() {
        this.$eventBus.$on('item-rendered', () => {
            const catalogCategoryProductPlaceholders = document.querySelector('.catalog-category__list--placeholder');
            if (catalogCategoryProductPlaceholders) {
                (catalogCategoryProductPlaceholders as HTMLElement).style.display = 'none';
            }
        });
    },
}).$mount('#solar-app');
